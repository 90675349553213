import dynamic from 'next/dynamic';
import { Builder } from '@builder.io/react/lite';

const TwoColumnTable = dynamic(async () => await import('./TwoColumnTable'));

Builder.registerComponent(TwoColumnTable, {
  name: 'TwoColumnTable',
  image: 'https://unpkg.com/css.gg@2.0.0/icons/svg/layout-grid.svg',
  inputs: [
    {
      name: 'leftColumnHeading',
      type: 'customRichTextPistachios',
      required: true,
    },
    {
      name: 'rightColumnHeading',
      type: 'customRichTextPistachios',
      required: true,
    },

    {
      name: 'rows',
      type: 'list',
      copyOnAdd: false,
      subFields: [
        {
          name: 'row',
          friendlyName: 'Column',
          type: 'list',
          copyOnAdd: false,
          helperText: 'Max of 2 Columns each row',
          onChange: `
            if (options.get('row').length > 2) {
              options.set('row', options.get('row').slice(0, 2))
              alert('maximum "Columns each row" is 2')
            }
          `,
          subFields: [
            {
              name: 'content',
              type: 'customRichTextPistachios',
              required: true,
            },
          ],
        },
      ],
    },
  ],
});
