import dynamic from 'next/dynamic';
import { Builder } from '@builder.io/react/lite';

const VimeoGallery = dynamic(async () => await import('./VimeoGallery'));

Builder.registerComponent(VimeoGallery, {
  name: 'VimeoGallery',
  image: 'https://unpkg.com/css.gg@2.0.0/icons/svg/film.svg',
  inputs: [
    {
      name: 'videos',
      type: 'list',
      copyOnAdd: false,
      subFields: [
        {
          name: 'videoId',
          type: 'string',
          required: true,
        },
      ],
    },
  ],
});
