import dynamic from 'next/dynamic';
import { Builder } from '@builder.io/react/lite';
import { buttonSubfields } from '@common/Button/Button.BuilderObj';

const ProductSizes = dynamic(async () => await import('./ProductSizes'));

Builder.registerComponent(ProductSizes, {
  name: 'ProductSizes',
  image: 'https://unpkg.com/css.gg@2.0.0/icons/svg/size.svg',
  inputs: [
    {
      name: 'title',
      type: 'string',
      required: true,
    },
    {
      name: 'subtitle',
      type: 'customRichTextPistachios',
      required: true,
    },
    {
      name: 'image',
      type: 'object',
      subFields: [
        {
          name: 'src',
          friendlyName: 'Image source',
          type: 'file',
          allowedFileTypes: ['jpeg', 'png'],
          required: true,
        },
        {
          name: 'altText',
          friendlyName: 'Image description text',
          type: 'string',
        },
      ],
    },
    {
      name: 'sizeLabel',
      friendlyName: 'Available Label',
      type: 'string',
    },
    {
      name: 'sizes',
      friendlyName: 'Sizes Available',
      type: 'longText',
      helperText: 'Enter sizes in a coma separated format eg: 200g, 150g, 1kg,',
      required: true,
    },
    {
      name: 'availability',
      friendlyName: 'Availability Disclaimer',
      type: 'string',
    },
    {
      name: 'button',
      friendlyName: 'Buy Now Button',
      type: 'object',
      ...buttonSubfields,
    },
  ],
});
