import { clsx } from '@wonderful/wwc/dist/src/helpers/clsx';
import DOMPurify from 'isomorphic-dompurify';
import { FindPistachiosProps } from './FindPistachios.types';
import Button from '@common/Button/Button';
import BuilderEditingHelper from '@common/BuilderEditingHelper/BuilderEditingHelper';
import Section from '@design/Section/Section';
import styles from './FindPistachios.module.scss';

export default function FindPistachios(props: FindPistachiosProps) {
  const { eyebrow, title, body, button } = props;
  const isVisible = !(title && body && button);
  return (
    <Section className="container">
      <BuilderEditingHelper
        componentName="FindPistachios"
        visible={isVisible}
      />
      <div className={clsx('row', styles.bgGradient)}>
        <div
          className={clsx('col-12', styles.innerContainer)}
          data-testid="find-pistachios"
        >
          {eyebrow && (
            <p className={styles.eyebrow} data-testid="eyebrow">
              {eyebrow}
            </p>
          )}
          <h2 className={styles.title}>{title}</h2>
          {body && (
            <div
              className={styles.description}
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(body) }}
              data-testid="description"
            />
          )}
          {button && button?.href && button?.text && (
            <div className={styles.buttonContainer}>
              <Button
                as="Link"
                href={button?.href}
                design={button?.design}
                className={styles.button}
                data-testid="where-button"
              >
                {button?.text}
              </Button>
            </div>
          )}
        </div>
      </div>
    </Section>
  );
}
