import dynamic from 'next/dynamic';
import { Builder } from '@builder.io/react/lite';

const Infographic = dynamic(async () => await import('./Infographic'));

Builder.registerComponent(Infographic, {
  name: 'Infographic',
  image: 'https://unpkg.com/css.gg@2.0.0/icons/svg/website.svg',
  inputs: [
    {
      name: 'pistachios',
      type: 'object',
      required: true,
      subFields: [
        {
          name: 'title',
          type: 'string',
          required: true,
        },
        {
          name: 'subtitle',
          type: 'customRichTextPistachios',
        },
        {
          name: 'image',
          type: 'object',
          subFields: [
            {
              name: 'src',
              friendlyName: 'Image source',
              type: 'file',
              allowedFileTypes: ['jpeg', 'png', 'svg'],
              required: true,
            },
            {
              name: 'altText',
              friendlyName: 'Image description text',
              type: 'string',
            },
          ],
        },
        {
          name: 'chart',
          friendlyName: 'Proteins Chart',
          type: 'object',
          required: true,
          subFields: [
            {
              name: 'title',
              type: 'customRichTextPistachios',
              helperText:
                'Note: This will always visually appear as a heading 3.',
              required: true,
            },
            {
              name: 'subtitle',
              type: 'customRichTextPistachios',
            },
            {
              name: 'items',
              friendlyName: 'Proteins List Items',
              type: 'list',
              copyOnAdd: false,
              subFields: [
                {
                  name: 'label',
                  type: 'string',
                  required: true,
                },
                {
                  name: 'grams',
                  friendlyName: 'Grams of protein',
                  type: 'number',
                  required: true,
                },
              ],
            },
            {
              name: 'label',
              friendlyName: 'Proteins List Footnote',
              type: 'string',
            },
          ],
        },
        {
          name: 'bullet',
          friendlyName: 'Nutrition Bullet Points',
          type: 'object',
          required: true,
          subFields: [
            {
              name: 'title',
              type: 'customRichTextPistachios',
              helperText:
                'Note: This will always visually appear as a heading 3.',
              required: true,
            },
            {
              name: 'bulletList',
              friendlyName: 'Bullet Points',
              type: 'list',
              copyOnAdd: false,
              subFields: [
                {
                  name: 'text',
                  type: 'customRichTextPistachios',
                  required: true,
                },
              ],
            },
          ],
        },
        {
          name: 'footnote',
          type: 'customRichTextPistachios',
        },
      ],
    },
    {
      name: 'almonds',
      type: 'object',
      subFields: [
        {
          name: 'title',
          type: 'string',
          required: true,
        },
        {
          name: 'subtitle',
          type: 'customRichTextPistachios',
        },
        {
          name: 'image',
          type: 'object',
          subFields: [
            {
              name: 'src',
              friendlyName: 'Image source',
              type: 'file',
              allowedFileTypes: ['jpeg', 'png', 'svg'],
              required: true,
            },
            {
              name: 'altText',
              friendlyName: 'Image description text',
              type: 'string',
            },
          ],
        },
        {
          name: 'chart',
          friendlyName: 'Proteins Chart',
          type: 'object',
          required: true,
          subFields: [
            {
              name: 'title',
              type: 'customRichTextPistachios',
              helperText:
                'Note: This will always visually appear as a heading 3.',
              required: true,
            },
            {
              name: 'subtitle',
              type: 'customRichTextPistachios',
            },
            {
              name: 'items',
              friendlyName: 'Proteins List Items',
              type: 'list',
              copyOnAdd: false,
              subFields: [
                {
                  name: 'label',
                  type: 'string',
                  required: true,
                },
                {
                  name: 'grams',
                  friendlyName: 'Grams of protein',
                  type: 'number',
                  required: true,
                },
              ],
            },
            {
              name: 'label',
              friendlyName: 'Proteins List Footnote',
              type: 'string',
            },
          ],
        },
        {
          name: 'bullet',
          friendlyName: 'Nutrition Bullet Points',
          type: 'object',
          required: true,
          subFields: [
            {
              name: 'title',
              type: 'customRichTextPistachios',
              helperText:
                'Note: This will always visually appear as a heading 3.',
              required: true,
            },
            {
              name: 'bulletList',
              friendlyName: 'Bullet Points',
              type: 'list',
              copyOnAdd: false,
              subFields: [
                {
                  name: 'text',
                  type: 'customRichTextPistachios',
                  required: true,
                },
              ],
            },
          ],
        },
        {
          name: 'footnote',
          type: 'customRichTextPistachios',
        },
      ],
    },
  ],
});
