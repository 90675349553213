import dynamic from 'next/dynamic';

import { Builder, withChildren } from '@builder.io/react/lite';
import { childComponents, childComponentsAsString } from './Container';

const Container = dynamic(async () => await import('./Container'));

const ContainerBuilderChildren = withChildren(Container);

Builder.registerComponent(ContainerBuilderChildren, {
  name: 'Container',
  image: 'https://unpkg.com/css.gg@2.0.0/icons/svg/extension-add.svg',
  inputs: [
    {
      name: 'Admin Tips',
      type: 'adminTips',
      defaultValue: `Accepts Child Components: ${childComponentsAsString}`,
    },
    {
      name: 'id',
      type: 'string',
      helperText: "ID's are useful for things like enabling anchor links",
    },
  ],
  childRequirements: {
    message: `You can only nest "${childComponentsAsString}" inside Container`,
    query: {
      'component.name': { $in: childComponents },
    },
  },
});
