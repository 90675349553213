import { Builder } from '@builder.io/react/lite';
import RelatedProducts from './RelatedProducts';
import config from '@helpers/config';
import { LocaleEnum } from '@store/enums';

const localizedBuilderProductData = {
  [LocaleEnum.EN_US]: config.builderProductModelId,
  [LocaleEnum.ES]: config.builderProductModelIdSp,
  [LocaleEnum.DE]: config.builderProductModelIdDe,
  [LocaleEnum.FR]: config.builderProductModelIdFr,
  [LocaleEnum.IT]: config.builderProductModelIdIt,
  [LocaleEnum.NL]: config.builderProductModelIdNl,
  [LocaleEnum.AR]: config.builderProductModelIdAr,
};

const localesArr = [];
for (const [_key, value] of Object.entries(LocaleEnum)) {
  localesArr.push(value);
}

// set localizeRef for product data model
let localizedRefId = localizedBuilderProductData[LocaleEnum.EN_US];
// only run client side
if (typeof window !== 'undefined') {
  localesArr.forEach(locale => {
    if (window.location.pathname.indexOf(`/${locale}`) === 0) {
      localizedRefId = localizedBuilderProductData[locale];
      return;
    }
  });
}

Builder.registerComponent(RelatedProducts, {
  name: 'RelatedProducts',
  image: 'https://unpkg.com/css.gg@2.0.0/icons/svg/stack.svg',
  inputs: [
    {
      name: 'title',
      type: 'string',
      required: true,
      defaultValue: 'Let your taste buds do some exploring',
    },
    {
      name: 'subtitle',
      type: 'customRichTextPistachios',
      required: true,
      defaultValue:
        'There’s more than one way to flavor a Wonderful® Pistachio. There are at least four.',
    },
    {
      name: 'defaultCollectionName',
      type: 'enum',
      enum: ['No Shells', 'In-Shell', 'Almonds', 'Flavours'],
      required: true,
      defaultValue: 'No Shells',
      helperText:
        'Set the primary related products collection that is displayed on page load below',
    },
    {
      name: 'firstProductsCollection',
      friendlyName: 'Primary Products Collection',
      type: 'list',
      copyOnAdd: false,
      subFields: [
        {
          name: 'product',
          type: 'reference',
          modelId: localizedRefId,
        },
      ],
    },
    {
      name: 'secondaryCollectionName',
      type: 'enum',
      enum: ['No Shells', 'In-Shell', 'Almonds', 'Flavours'],
      required: true,
      defaultValue: 'In-Shell',
      helperText:
        'Set the secondary related products collection that the switch toggles to below',
    },
    {
      name: 'secondProductsCollection',
      friendlyName: 'Secondary Products Collection',
      type: 'list',
      copyOnAdd: false,
      subFields: [
        {
          name: 'product',
          type: 'reference',
          modelId: localizedRefId,
        },
      ],
      onChange: `
        if (options.get('secondProductsCollection').length > options.get('firstProductsCollection').length) {
          options.set('secondProductsCollection', options.get('secondProductsCollection').slice(0, options.get('firstProductsCollection').length))
          alert('Secondary Products Collection cannot exceed the amount of products for Primary Products Collection')
        }
      `,
    },
  ],
});
