import styles from './SectionWithChildren.module.scss';
import BuilderImage from '@common/BuilderImage/BuilderImage';
import { clsx } from '@wonderful/wwc/dist/src/helpers/clsx';
import BuilderEditingHelper from '@common/BuilderEditingHelper/BuilderEditingHelper';
import NextImage from 'next/image';
import type { SectionWithChildrenProps } from './SectionWithChildren.types';

export const childComponents = ['InfoList'];
export const childComponentsAsString = childComponents
  .toString()
  .replace(/,/g, ', ');

export default function SectionWithChildren(props: SectionWithChildrenProps) {
  const { backgroundImage, children } = props;

  return (
    <section className={styles.section} data-testid="section-with-children">
      <div className={styles.bgImgContainer}>
        {backgroundImage ? (
          <BuilderImage
            imageSrc={backgroundImage}
            mobileWidth={'576px'}
            tabletWidth={'992px'}
            desktopWidth={'1200px'}
          />
        ) : (
          <NextImage
            src={'/images/SectionWithChildren/lg.jpeg'}
            alt={''}
            layout="fill"
          />
        )}
      </div>

      <div className={clsx(styles.innerContainer, 'container')}>
        <div className="row">
          <div className="col-10 offset-1">
            {/* nested child components */}
            <div className={styles.offsetContainer}>
              <BuilderEditingHelper
                componentName="SectionWithChildren"
                helperText={`Drag [${childComponentsAsString}] component${
                  childComponents.length > 1 ? 's' : ''
                } from the Insert menu and drop it here to add it as a child`}
                visible={!children || children?.length === 0}
              />
              {children && children}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
