import { Builder, builder } from '@builder.io/react/lite'; // lite version without build in components.
// If you would like to use any build in Builder component you have to import it manually like this:
// import '@builder.io/react/dist/lib/src/blocks/Button';
// import '@builder.io/react/dist/lib/src/blocks/Columns';

import './TextBox/TextBox.builder';
import './TwoColumnTable/TwoColumnTable.builder';
import './Container/Container.builder';
import '../common/Heading/Heading.builder';
import './SectionWithChildren/SectionWithChildren.builder';
import './Spacer/Spacer.builder';
import './ProductCarousel/ProductCarousel.builder';
import './ProductListing/ProductListing.builder';
import './ProductSizes/ProductSizes.builder';
import './ProductMain/ProductMain.builder';
import './RelatedProducts/RelatedProducts.builder';
import './FindPistachios/FindPistachios.builder';
import './HeadingBlock/HeadingBlock.builder';
import './Hero/Hero.builder';
import './FilterableWhereToBuy/FilterableWhereToBuy.builder';
import './FilterableAccordion/FilterableAccordion.builder';
import './WhoWeAre/WhoWeAre.builder';
import './CampaignVimeoModal/CampaignVimeoModal.builder';
import './VimeoGallery/VimeoGallery.builder';
import './Infographic/Infographic.builder';
import './Vote/Vote.builder';

// RECOMMENDED WHEN USING BUILDER LITE !
// use this to hide all default built-in components and fully manage
// the insert menu components and sections yourself
Builder.set({ customInsertMenu: true });

type Item = {
  name: string;
  exclude?: Array<string>;
};

type BuilderCustomComponents = {
  name: string;
  items: Item[];
  allowedModels: Array<string>;
};

const productPageModels = [
  'product-page',
  'product-page-es',
  'product-page-de',
  'product-page-fr',
  'product-page-it',
  'product-page-nl',
  'product-page-ar',
];

const pageLanguageModels = [
  'page',
  'page-es',
  'page-de',
  'page-fr',
  'page-it',
  'page-nl',
  'page-ar',
];

const generalComponents: BuilderCustomComponents = {
  name: 'General Components',
  items: [
    { name: 'TextBox' },
    { name: 'HeadingBlock' },
    { name: 'ProductCarousel' },
    { name: 'Hero' },
    { name: 'ProductListing' },
    { name: 'FilterableWhereToBuy' },
    { name: 'FilterableAccordion' },
    { name: 'WhoWeAre' },
    { name: 'FindPistachios' },
    { name: 'CampaignVimeoModal' },
    { name: 'VimeoGallery' },
    { name: 'Infographic' },
    { name: 'Vote' },
  ],
  allowedModels: pageLanguageModels,
};

const layoutComponents: BuilderCustomComponents = {
  name: 'Layout Components',
  items: [
    { name: 'SectionWithChildren' },
    { name: 'Spacer' },
    { name: 'Container' },
    { name: 'TwoColumnTable' },
  ],
  allowedModels: pageLanguageModels,
};

const productComponents: BuilderCustomComponents = {
  name: 'Product Components',
  items: [
    { name: 'ProductMain' },
    { name: 'ProductSizes' },
    { name: 'RelatedProducts' },
  ],
  allowedModels: productPageModels,
};

const components: BuilderCustomComponents[] = [
  generalComponents,
  layoutComponents,
  productComponents,
];

components.forEach(component => {
  if (component.allowedModels.includes(builder.editingModel!)) {
    Builder.register('insertMenu', {
      name: component.name,
      items: component.items.filter(({ name, exclude }) => {
        if (!exclude?.includes(builder.editingModel!)) {
          return { name };
        }
      }),
    });
  }
});
