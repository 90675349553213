import dynamic from 'next/dynamic';
import { Builder } from '@builder.io/react/lite';

import { buttonSubfields } from '@common/Button/Button.BuilderObj';

const FilterableWhereToBuy = dynamic(
  async () => await import('./FilterableWhereToBuy')
);

Builder.registerComponent(FilterableWhereToBuy, {
  name: 'FilterableWhereToBuy',
  image: 'https://unpkg.com/css.gg@2.0.0/icons/svg/carousel.svg',
  inputs: [
    {
      name: 'isFilterEnabled',
      type: 'boolean',
      helperText:
        'Filter is automatically generated based off of country titles',
      defaultValue: true,
    },
    {
      name: 'defaultFilter',
      friendlyName: 'Default Active Country',
      helperText:
        'Please note that the filter value entered here must match a country title created in the list of countries below.',
      type: 'string',
    },
    {
      name: 'countries',
      type: 'list',
      copyOnAdd: false,
      subFields: [
        {
          name: 'title',
          type: 'string',
          required: true,
        },
        {
          name: 'stores',
          type: 'list',
          copyOnAdd: false,
          subFields: [
            {
              name: 'title',
              type: 'string',
              required: true,
            },
            {
              name: 'image',
              type: 'object',
              subFields: [
                {
                  name: 'src',
                  friendlyName: 'Image source',
                  type: 'file',
                  allowedFileTypes: ['jpeg', 'png', 'svg'],
                  required: true,
                },
              ],
            },
            {
              name: 'button',
              friendlyName: 'Buy Now Button',
              type: 'object',
              ...buttonSubfields,
            },
          ],
        },
      ],
    },
  ],
});
