import dynamic from 'next/dynamic';

import { Builder, withChildren } from '@builder.io/react/lite';
import {
  childComponents,
  childComponentsAsString,
} from './SectionWithChildren';

const SectionWithChildren = dynamic(
  async () => await import('./SectionWithChildren')
);

const SectionWithBuilderChildren = withChildren(SectionWithChildren);

Builder.registerComponent(SectionWithBuilderChildren, {
  name: 'SectionWithChildren',
  image: 'https://unpkg.com/css.gg@2.0.0/icons/svg/extension-add.svg',
  inputs: [
    {
      name: 'Admin Tips',
      type: 'adminTips',
      defaultValue: `Accepts Child Components: ${childComponentsAsString}`,
    },
    {
      name: 'backgroundImage',
      type: 'file',
      friendlyName: 'Background Image',
      allowedFileTypes: ['jpeg', 'png'],
      required: true,
    },
  ],
  childRequirements: {
    message: `You can only nest "${childComponentsAsString}" inside SectionWithChildren`,
    query: {
      'component.name': { $in: childComponents },
    },
  },
  // below is an example of how to include default children
  // defaultChildren: [
  //   {
  //     '@type': '@builder.io/sdk:Element',
  //     component: {
  //       name: 'InfoList',
  //       options: {
  //         /* optional - default props to pass to InfoList */
  //       },
  //     },
  //   },
  // ],
});
