import dynamic from 'next/dynamic';
import { Builder } from '@builder.io/react/lite';

const FilterableAccordion = dynamic(
  async () => await import('./FilterableAccordion')
);

Builder.registerComponent(FilterableAccordion, {
  name: 'FilterableAccordion',
  image: 'https://unpkg.com/css.gg@2.0.0/icons/svg/layout-list.svg',
  inputs: [
    {
      name: 'isFilterEnabled',
      type: 'boolean',
      helperText:
        'Filters are automatically generated based on the tags you provide to the questions',
      defaultValue: true,
    },
    {
      name: 'faqFilterTags',
      friendlyName: 'Filter Tags',
      type: 'list',
      copyOnAdd: false,
      subFields: [
        {
          name: 'tag',
          type: 'string',
          required: true,
        },
      ],
    },
    {
      name: 'questions',
      type: 'list',
      copyOnAdd: false,
      subFields: [
        {
          name: 'question',
          type: 'customRichTextPistachios',
          required: true,
        },
        {
          name: 'answer',
          type: 'customRichTextPistachios',
          required: true,
        },
        {
          name: 'tags',
          type: 'list',
          copyOnAdd: false,
          subFields: [
            {
              name: 'tag',
              friendlyName: 'Tag Name',
              type: 'string',
              required: true,
            },
          ],
        },
      ],
    },
  ],
});
