import { Builder } from '@builder.io/react/lite';
import ProductCarousel from './ProductCarousel';
import config from '@helpers/config';
import { LocaleEnum } from '@store/enums';

const localizedBuilderProductData = {
  [LocaleEnum.EN_US]: config.builderProductModelId,
  [LocaleEnum.ES]: config.builderProductModelIdSp,
  [LocaleEnum.DE]: config.builderProductModelIdDe,
  [LocaleEnum.FR]: config.builderProductModelIdFr,
  [LocaleEnum.IT]: config.builderProductModelIdIt,
  [LocaleEnum.NL]: config.builderProductModelIdNl,
  [LocaleEnum.AR]: config.builderProductModelIdAr,
};

const localesArr = [];
for (const [_key, value] of Object.entries(LocaleEnum)) {
  localesArr.push(value);
}

// set localizeRef for product data model
let localizedRefId = localizedBuilderProductData[LocaleEnum.EN_US];
// only run client side
if (typeof window !== 'undefined') {
  localesArr.forEach(locale => {
    if (window.location.pathname.indexOf(`/${locale}`) === 0) {
      localizedRefId = localizedBuilderProductData[locale];
      return;
    }
  });
}

Builder.registerComponent(ProductCarousel, {
  name: 'ProductCarousel',
  image: 'https://unpkg.com/css.gg@2.0.0/icons/svg/stack.svg',
  inputs: [
    {
      name: 'title',
      type: 'string',
      required: true,
    },
    {
      name: 'eyebrow',
      type: 'string',
      required: true,
    },
    {
      name: 'body',
      friendlyName: 'Text body',
      type: 'customRichTextPistachios',
      required: true,
    },
    {
      name: 'products',
      type: 'list',
      copyOnAdd: false,
      subFields: [
        {
          name: 'product',
          type: 'reference',
          modelId: localizedRefId,
        },
      ],
    },
  ],
});
