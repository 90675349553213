import { Builder } from '@builder.io/react/lite';
import config from '@helpers/config';
import { LocaleEnum } from '@store/enums';
import { ColorTextEnum } from '@store/types';
import { buttonSubfields } from '@common/Button/Button.BuilderObj';
import Vote from './Vote';

const localesArr = [
  LocaleEnum.EN_US,
  LocaleEnum.ES,
  LocaleEnum.DE,
  LocaleEnum.FR,
  LocaleEnum.IT,
  LocaleEnum.NL,
  LocaleEnum.AR,
];

// set currentLocale for Poll API requests
let currentLocale = LocaleEnum.EN_US;
if (typeof window !== 'undefined') {
  localesArr.forEach(locale => {
    if (window.location.pathname.indexOf(`/${locale}`) === 0) {
      currentLocale = locale;
      return;
    }
  });
}

const colorArr = [...Object.keys(ColorTextEnum)];

Builder.registerComponent(Vote, {
  name: 'Vote',
  image: 'https://unpkg.com/css.gg@2.0.0/icons/svg/poll.svg',
  inputs: [
    {
      name: 'createPoll',
      friendlyName: 'Poll Voting Settings',
      type: 'createPoll',
      options: {
        locale: currentLocale,
        apiKey: config.pollApiKey,
      },
      helperText: `This sets the Poll ID that the Voting button submits to. Publish the page whenever poll setting changes are made.`,
    },
    {
      name: 'Admin Tips',
      type: 'adminTips',
      defaultValue: `
     The content for the Vote component is edited in the fields below
      `,
    },
    {
      name: 'eyebrow',
      type: 'longText',
      required: true,
    },
    {
      name: 'versus',
      type: 'text',
      required: true,
      helperText: '"OR" text between the poll option names',
    },
    {
      name: 'preTitle',
      type: 'longText',
      required: true,
      helperText: 'Title before submission',
    },
    {
      name: 'preSubtitle',
      type: 'customRichTextPistachios',
      required: true,
      helperText: 'Subtitle before submission',
    },
    {
      name: 'selectedTitle',
      type: 'longText',
      required: true,
      helperText: 'Title when a product is selected',
    },
    {
      name: 'postSubtitle',
      type: 'longText',
      required: true,
      helperText: 'Subtitle when a vote is submitted',
    },
    {
      name: 'voteButton',
      friendlyName: 'Vote Button',
      helperText: 'Button text before the vote is submitted',
      type: 'text',
      required: true,
    },
    {
      name: 'button',
      friendlyName: 'Post Button',
      helperText: 'Button settings after the vote is submitted',
      type: 'object',
      required: true,
      ...buttonSubfields,
    },
    {
      name: 'tiedPostTitle',
      type: 'longText',
      required: true,
      helperText: 'Title when a vote is tied',
    },
    {
      name: 'tiedPostSubtitle',
      type: 'longText',
      required: true,
      helperText: 'Subtitle when a vote is tied',
    },
    {
      name: 'items',
      friendlyName: 'Poll Option',
      type: 'list',
      copyOnAdd: false,
      helperText: 'Max of 2 Poll Options',
      onChange: `
        if (options.get('items').length > 2) {
          options.set('items', options.get('items').slice(0, 2))
          alert('maximum "Poll Options" is 2')
        }
      `,
      subFields: [
        {
          name: 'name',
          type: 'string',
          required: true,
        },
        {
          name: 'id',
          type: 'number',
          defaultValue: 0,
          hideFromUI: true,
        },
        {
          name: 'image',
          type: 'object',
          subFields: [
            {
              name: 'src',
              friendlyName: 'Image source',
              type: 'file',
              allowedFileTypes: ['jpeg', 'png'],
              required: true,
            },
            {
              name: 'altText',
              friendlyName: 'Image description text',
              type: 'string',
            },
          ],
        },
        {
          name: 'color',
          type: 'string',
          enum: colorArr,
          required: true,
        },
        {
          name: 'selectionText',
          type: 'longText',
          required: true,
          helperText: 'Text if the option is selected',
        },
        {
          name: 'resultsText',
          type: 'longText',
          helperText: 'Text if the option wins the vote',
          required: true,
        },
      ],
    },
  ],
});
