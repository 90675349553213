import React, { useState, useEffect } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { clsx } from '@wonderful/wwc/dist/src/helpers/clsx';
import DOMPurify from 'isomorphic-dompurify';
import { useTranslation } from 'next-i18next';
// Import Swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { swiperInit } from '@helpers/swiper';
swiperInit();
import { Builder } from '@builder.io/react/lite';

import BuilderEditingHelper from '@common/BuilderEditingHelper/BuilderEditingHelper';
import { getBuilderPreviewProductsData } from '@helpers/getBuilderProductData';
import { Breakpoints } from '@helpers/constants';
import { ProductCarouselModule } from './ProductCarousel.types';

import styles from './ProductCarousel.module.scss';
import Section from '@design/Section/Section';
import BuilderImage from '@common/BuilderImage/BuilderImage';
import { SingleProductType } from '@store/types';
import { LocaleEnum } from '@store/enums';

import { useIsRtl } from '@helpers/utils';

//PROBABLY WE SHOULD FETCH SHELL/NOSHELL INFO FROM BACKEND BUT UNTIL THEN WE CAN USE THIS FUNCTION
const noShellChecker = (url: string) => {
  return /-no-shell\/$/i.test(url);
};

function ProductCarousel(props: ProductCarouselModule) {
  const { eyebrow, title, body } = props;
  const [isInViewport, setIsInViewport] = useState(false);

  const [products, setProducts] = useState(props.products);

  const { locale } = useRouter();

  useEffect(() => {
    async function setPreviewData() {
      const mergedProducts = await getBuilderPreviewProductsData(
        props.products,
        locale as LocaleEnum
      );
      mergedProducts && setProducts(mergedProducts);
    }

    Builder.isPreviewing && setPreviewData();
  }, [props.products, locale]);

  const productDataExists =
    typeof products !== 'undefined' && products.length > 0;

  const onEnterViewport = () => {
    setIsInViewport(true);
  };

  if (!products) {
    return (
      <BuilderEditingHelper
        componentName="ProductCarousel"
        visible={!productDataExists}
      />
    );
  }

  return (
    <Section
      className={clsx(
        'container',
        styles.container,
        isInViewport ? styles.inViewport : ''
      )}
      viewportThreshold={0.3}
      onEnterViewport={onEnterViewport}
    >
      <div className={styles.titles}>
        <span>{eyebrow}</span>
        <h2>{title}</h2>
        {body && (
          <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(body) }} />
        )}
      </div>

      <ResponsiveSwiper items={products} />
    </Section>
  );
}

const ResponsiveSwiper = ({ items }: { items: SingleProductType[] }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const { t } = useTranslation('common');

  const isRtl = useIsRtl();

  // TODO: EMEAWP-373 - Update swiper to v11 and can uncomment this and TODO below for fix
  // const numberOfDuplicates = items?.length - 3;
  return (
    <Swiper
      className={clsx(styles.swiper, 'swiper-no-swiping')}
      data-testid="product-carousel-swiper"
      centeredSlides
      loop
      resistance
      resistanceRatio={1}
      slidesPerView={3}
      // TODO: EMEAWP-373 - Update swiper to v11 and can uncomment this for fix. loopAdditionalSlides was removed in v9
      // loopAdditionalSlides={numberOfDuplicates}
      navigation
      pagination={{
        clickable: true,
      }}
      noSwiping={false}
      shortSwipes={true}
      onSlideChange={t => {
        setActiveIndex(t.realIndex);
      }}
      a11y={{
        enabled: true,
      }}
      breakpoints={{
        [Breakpoints.md]: {
          noSwiping: true,
        },
        [Breakpoints.lg]: {
          slidesPerView: 5,
        },
      }}
      dir={isRtl ? 'rtl' : 'ltr'}
    >
      {items[activeIndex].product !== undefined && (
        <span id="ada-content" slot="container-start">
          {items[activeIndex].product.value?.data?.productDetailUrl !==
            undefined && (
            <Link href={items[activeIndex].product.value.data.productDetailUrl}>
              <a aria-atomic="true" aria-live="polite">
                <strong>
                  {items[activeIndex].product.value?.data.name}
                  {noShellChecker(
                    items[activeIndex].product.value?.data.productDetailUrl
                  )
                    ? `- ${t('No Shells')}`
                    : ''}
                </strong>
              </a>
            </Link>
          )}
        </span>
      )}

      {items?.map((item, index) => (
        <SwiperSlide
          aria-hidden="true"
          key={index}
          className={styles.swiperItem}
        >
          <Link
            href={
              item.product?.value?.data.productDetailUrl
                ? item.product?.value.data.productDetailUrl
                : ''
            }
          >
            <a tabIndex={-1} aria-hidden="true">
              <div className={styles.imageWrapper}>
                <BuilderImage
                  imageSrc={item.product?.value?.data.image}
                  mobileWidth={'132px'}
                  tabletWidth={'222px'}
                  alt={item.product?.value?.data.imageAltText || ''}
                />
                <div className={`${styles.swiperItemInfo} `}>
                  <strong>{item.product?.value?.data.name}</strong>
                </div>
              </div>
            </a>
          </Link>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default ProductCarousel;
